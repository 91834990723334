<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoice === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="invoice"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >

        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 color-dark"
            >
              <!-- Header: Left Content -->
              <div style="color: black; font-size: 16px">
                <h4
                  style="color: black; font-size: 16px"
                  class="mb-0"
                >
                  ONE SUB
                </h4>
                <span
                  class="mb-0"
                >
                  Rue naplous 1002 tunis
                </span>
                <b-card-text class="mb-0">
                  Phone:+216 31 54 28 27
                </b-card-text>
                <b-card-text class="mb-0">
                  Fax:+216 31 54 28 27
                </b-card-text>
                <b-card-text class="mb-0">
                  Email:info@onesub.com.tn                  </b-card-text>

              </div>
              <div style="color: black">
                <div class="logo-wrapper mb-4">
                  <img
                    src="@/assets/images/logo/logo.jpeg"
                    width="150px"
                    height="auto"
                  >
                  <h3 class="text-primary invoice-logo" />
                </div>
              </div>

              <!-- Header: Right Content -->
              <!-- Header: Right Content -->
            </div>

          </b-card-body>
          <b-card-body>
            <div
              style="color: black"
              class="d-flex flex-row justify-content-between align-items-center"
            >

              <div>
                <h3
                  style="color: black; font-size: 25px"
                >
                  INVOICE N°
                  <span

                    style="color: black ; font-size: 20px;"
                  >
                    {{ invoice.reference }}
                  </span>

                  <div class="mt-1">
                    <h4 style="color: black">
                      Date:
                      <span
                        style="color: black"
                      >
                        {{ format(invoice.date) }}</span>
                    </h4>
                  </div>
                </h3>
              </div>
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
              >
                <!-- Header: Left Content -->

                <!-- Header: Right Content -->
                <div
                  v-if="invoice.customer"
                  class="mb-2 mt-0 ml-1 mr-1"
                  style="border: 2px solid black;  border-radius: 18px; font-size:14px"
                >
                  <b-card-text
                    style="font-size:14px"
                    class="ml-2 mr-2 mt-2 mb-1 h3 text-dark font-weight-bold"
                  >
                    Code : {{ invoice.customer.code }}
                  </b-card-text>
                  <h4
                    style="font-size:14px ;color:black"
                    class="font-weight-bolder ml-2 mr-1"
                  >
                    Buyer : {{ invoice.customer.buyer_name }}
                  </h4>
                  <b-card-text
                    style="font-size:14px"
                    class="ml-2 mr-2 mb-1 h3 text-dark font-weight-bold"
                  >
                    <span
                      style="font-size:14px ;color:black"
                      class="font-weight-bold"
                    > {{ invoice.customer.buyer_address }}
                    </span>
                  </b-card-text>
                  <h4
                    style="font-size:14px ;color:black"
                    class="font-weight-bolder ml-2 mr-1"
                  >
                    Importer : {{ invoice.customer.importer_name }}
                  </h4>
                  <b-card-text
                    style="font-size:14px"
                    class="ml-2 mr-2 mb-1 h3 text-dark font-weight-bold"
                  >
                    <span
                      class="font-weight-bold"
                    >  {{ invoice.customer.importer_address }}
                    </span>
                  </b-card-text>

                </div>
              </div>

            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-card-body
            class="invoice-padding pt-1"
          >
            <table class="mb-1 center ml-2">
              <tr>
                <th class="tht">
                  CONTAINER N°
                </th>
                <th class="tht">
                  Total PACKAGES
                </th>
                <th class="tht">
                  Total G.W.
                </th>
                <th class="tht">
                  Total N.W.
                </th>
                <th class="tht">
                  CBM
                </th>
              </tr>
              <tr>
                <td class="tdt">
                  {{ invoice.container }}
                </td>
                <td class="tdt">
                  {{ sommeQte }} Cartons                </td>
                <td class="tdt">
                  {{ sommeGW }} KGS
                </td>
                <td class="tdt">
                  {{ sommeNW }} KGS
                </td>
                <td class="tdt">
                  {{ sommeCBM.toFixed(3) }} M<sup>3</sup>
                </td>

              </tr>

            </table>
            <b-table-lite
              class="ta"
              responsive
              :items="p"
              :fields="['item','ean' , 'description','unit','qty','pcs/ctn','nw/ctn','gw/ctn', 'U.PRIC','total']"
            >
              <template #cell(taskDescription)="data">
                <b-card-text
                  class="font-weight-bold mb-25"
                  style="font-size: 12px; border: 2px solid black"
                >
                  {{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>
          </b-card-body>
          <!-- Invoice Description: Total -->

          <div

            style="display: flex; justify-content: space-between"
          >
            <div
              class="ml-2"
              style="font-size: 16px"
            >
              <h5 style="color:black;font-size:16px">
                TERMS OF SALE AND OTHER COMMENTS
              </h5>
              <hr>
              <div v-html="invoice.note_payment" />
            </div>
            <div
              class="mb-4"
              style="font-size: 16px"
            >
              <table
                class="table"
              >
                <tbody>
                  <tr>
                    <th scope="row">
                      Subtotal :
                    </th>
                    <td>{{ invoice.subtotal }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Freight :
                    </th>
                    <td>{{ invoice.freight }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Other :
                    </th>
                    <td>{{ invoice.other }}</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      Total :
                    </th>
                    <td>{{ invoice.total }}</td>
                  </tr>
                </tbody>

              </table>

            </div>
          </div>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click=" $router.push({
              name: 'show-proforma-invoice',
              params: { id: id} ,
            })"
          >
            Proforma invoice
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import qs from 'qs'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'Client',
          formatter: customer => `${customer.company_name}`,
        },
        {
          key: 'customer.address',
          label: 'address',
        },
        {
          key: 'customer.phone',
          label: 'téléphone',
        },
        {
          key: 'customer.tax_identification_number',
          label: 'MF',
        },
      ],
      p: [],
      test: '0',
      invoice: [],
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getInvoice()
  },
  methods: {
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatDate(date) {
      if (!date) {
        return null
      }
      const [year] = date.split('-')
      return `${year.slice(2, 4)}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [month, day, year] = date.split('/')
      return `${year.padStart(0, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    async getInvoice() {
      await this.$http.get(`/invoices/detail/${this.$route.params.id}/`).then(response => {
        this.invoice = response.data
      })
      if (this.invoice != null) {
        this.$http.post(
          '/detail-products-invoices/',
          { invoice: this.invoice.id, products: this.invoice.products },
        ).then(res => {
          const result = res.data
          for (let i = 0; i < result.length; i += 1) {
            const qte = result[i].quantity
            this.invoice.products[i].pv_ctn_export = result[i].price
            const total = qte * this.invoice.products[i].pv_ctn_export
            const sommeNW = this.invoice.products[i].nw_export * qte
            const sommeGW = this.invoice.products[i].gw_export * qte
            const sommeCBM = this.invoice.products[i].cbm_export * qte
            this.addnewitem(this.invoice.products[i].item_code, this.invoice.products[i].ean, this.invoice.products[i].description, qte, this.invoice.products[i].nbr_export, this.invoice.products[i].nw_export, this.invoice.products[i].gw_export, this.invoice.products[i].pv_ctn_export.toFixed(2), total.toFixed(2))
            this.sommeQte += Number(qte)
            this.sommeNW += Number(sommeNW)
            this.sommeGW += Number(sommeGW)
            this.sommeCBM += Number(sommeCBM)
          }
        })
      }
    },

    // eslint-disable-next-line camelcase
    addnewitem(item, ean, description, qty, pcs, nw, gw, cost, total) {
      this.p.push({
        item,
        ean,
        description,
        'U.PRIC': cost,
        'pcs/ctn': pcs,
        qty,
        total,
        'nw/ctn': nw,
        'gw/ctn': gw,
        unit: 'CTN',
      })
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const sommeQte = 0
    const sommeCBM = 0
    const sommeGW = 0
    const sommeNW = 0

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      printInvoice,
      sommeQte,
      sommeCBM,
      sommeGW,
      sommeNW,

    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.tdt
{
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}
.tht
{
  padding: 10px;
  text-align: left;
}
.ta {
    white-space: nowrap;
    font-size: 12px;
    color: black;
  }
table {
  width: 100%;
}

.contact {
  background-color: #0b6746;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
   .ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 12px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .tdt
  {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }
  .tht
  {
    padding: 10px;
    text-align: left;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
